<template>
  <div class="notification-container">
    <notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    ></notification>
  </div>
</template>

<script>
import notification from "./notification.vue";
import { mapState } from "vuex";

export default {
  name: "notifications-container",
  components: {
    notification,
  },
  computed: mapState("notify", ["notifications"]),
};
</script>

<style lang="scss" scoped>
/*.notification-container {*/
/*position: fixed;*/
/*right: 20px;*/
/*bottom: 20px;*/
/*z-index: 10000;*/
/*}*/
</style>
