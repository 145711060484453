import apiClient from "@/api/api-client";

export const getUsers = async (params) => {
  const { data } = await apiClient.get("/users", { params });
  return data.data;
};

export const getUser = async (userId) => {
  const { data } = await apiClient.get(`/user/${userId}`);
  return data.data;
};

export const getRoles = async () => {
  return ["annotator", "verifier", "admin"];
};

export const createUser = async (user) => {
  const { data } = await apiClient.post(`/users`, user);
  return data;
};

export const updateUser = async (userId, user) => {
  const { data } = await apiClient.post(`/user/${userId}`, user);
  return data;
};

export const changeUserPassword = async (userId, password) => {
  const { data } = await apiClient.post(`/user/${userId}`, {
    action: "change_password",
    password,
  });
  return data;
};

export const deleteUser = async () => {
  return null;
};

export const forgotPassword = async (_id) => {
  let { data } = await apiClient.post(`/user/${_id}`, {
    action: "forgot_password",
  });
  return data;
};

export const forgotPasswordByLogin = async (username) => {
  let { data } = await apiClient.post(`/user`, {
    action: "forgot_password_login",
    login: username,
  });
  return data;
};
