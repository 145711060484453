<template>
  <span></span>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "notifications-item",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      types: {
        success: "Успешно",
        error: "Ошибка",
        warning: "Внимание",
        info: "Инфо",
      },
    };
  },
  mounted() {
    if (this.notification.size === "mini") {
      this.$message[this.notification.type](this.notification.message);
    } else {
      this.$notify[this.notification.type]({
        title:
          this.notification.title ||
          this.types[this.notification.type] ||
          this.notification.type,
        message: this.notification.message,
        showClose: true,
        duration: 2000,
        ...this.notification.options,
      });
    }
    this.timeout = setTimeout(() => this.remove(this.notification.id), 5000);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: mapActions("notify", ["remove"]),
};
</script>
