import { createStore } from "vuex";
import user from "./user";
import notify from "./notify";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    notify,
  },
});
