export default {
  namespaced: true,
  states: {
    user: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      if (state.user) {
        return true;
      }
      return false;
    },
    getUserScopes: (state) => {
      if (state.user) {
        return state.user.scopes;
      }
      return [];
    },
  },
};
