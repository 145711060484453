<template>
  <div
    class="
      fixed
      w-full
      h-full
      bg-white
      top-0
      left-0
      z-50
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-100
      text-gray-900
    "
  >
    <!-- Waves Background -->
    <div class="absolute right-0 bottom-0 left-0">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -100 1440 320">
        <path
          fill="#EDF2F7"
          fill-opacity="1"
          d="M0,96L26.7,112C53.3,128,107,160,160,181.3C213.3,203,267,213,320,192C373.3,171,427,117,480,122.7C533.3,128,587,192,640,229.3C693.3,267,747,277,800,245.3C853.3,213,907,139,960,138.7C1013.3,139,1067,213,1120,224C1173.3,235,1227,181,1280,154.7C1333.3,128,1387,128,1413,128L1440,128L1440,320L1413.3,320C1386.7,320,1333,320,1280,320C1226.7,320,1173,320,1120,320C1066.7,320,1013,320,960,320C906.7,320,853,320,800,320C746.7,320,693,320,640,320C586.7,320,533,320,480,320C426.7,320,373,320,320,320C266.7,320,213,320,160,320C106.7,320,53,320,27,320L0,320Z"
        ></path>
      </svg>
    </div>
    <!-- END Waves Background -->

    <!-- Log In Section -->
    <div
      class="
        px-5
        py-6
        lg:px-6 lg:py-8
        w-full
        md:w-8/12
        lg:w-6/12
        xl:w-4/12
        relative
      "
    >
      <!-- Logo -->

      <div class="rounded border p-6 lg:p-10 shadow-sm bg-white">
        <div>
          <label class="block text-gray-700">
            {{ frgPassShow ? "Почта" : "Логин" }}
            <el-input
              class="mt-2"
              v-model="username"
              placeholder="Логин"
            ></el-input>
          </label>
          <label v-if="!frgPassShow" class="block text-gray-700 mt-4">
            Пароль
            <el-input
              class="mt-2"
              show-password
              type="password"
              v-model="password"
              placeholder="Пароль"
              @keydown.enter.prevent="login"
            ></el-input>
          </label>
          <div class="text-right">
            <el-button @click="frgPassShow = !frgPassShow" type="text">{{
              frgPassShow ? "Назад" : "Забыли пароль ?"
            }}</el-button>
          </div>
          <div class="mt-6">
            <button
              v-if="frgPassShow"
              :disabled="loading"
              @click="forgotPassword"
              type="submit"
              class="
                px-4
                py-3
                bg-blue-500
                text-white
                rounded
                hover:bg-blue-700
                focus:outline-none focus:shadow-outline
                block
                w-full
              "
            >
              Отправить пароль на почту
            </button>
            <button
              v-else
              :disabled="loading"
              @click="login"
              type="submit"
              class="
                px-4
                py-3
                bg-blue-500
                text-white
                rounded
                hover:bg-blue-700
                focus:outline-none focus:shadow-outline
                block
                w-full
              "
            >
              Войти
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, whoami } from "@/api/auth";
import { forgotPasswordByLogin } from "@/api/users";

export default {
  name: "index",
  data: () => ({
    username: null,
    password: null,
    loading: false,
    frgPassShow: false,
    forgotForm: "",
  }),
  methods: {
    async forgotPassword() {
      this.loading = true;
      try {
        let data = await forgotPasswordByLogin(this.username);
        if (data._success) {
          this.$store.dispatch("notify/add", {
            type: "success",
            message: "Отправлено в вашу почте ссылку для сброса пароля",
            size: "mini",
          });
          this.username = "";
          this.frgPassShow = false;
        }
      } catch (err) {
        console.log(err);
        this.$store.dispatch("notify/add", {
          type: "error",
          message: "Произошла ошибка",
        });
      }
      this.loading = false;
    },
    async login() {
      this.loading = true;
      try {
        await login(this.username, this.password);
        this.$message({
          title: "Success",
          message: "Успешно",
          type: "success",
        });
        const user = await whoami();
        this.$store.commit("user/setUser", user);
        this.$router.push({ name: "Home" });
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  @apply rounded-full;
}
</style>
