let nextId = 1;
export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    PUSH(state, notification) {
      state.notifications.push({
        ...notification,
        id: nextId++,
      });
    },
    DELETE(state, notification_id) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== notification_id
      );
    },
  },
  actions: {
    add({ commit }, notification) {
      commit("PUSH", notification);
    },
    remove({ commit }, notification_id) {
      commit("DELETE", notification_id);
    },
  },
};
