<template>
  <Auth />
</template>

<script>
import Auth from "@/views/Auth";
export default {
  components: {
    Auth,
  },
};
</script>
